import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BeratungKlartextPage = () => (
  <Layout>
    <SEO title="BeratungKlartext" />

    <h1 id="ueberschrift">Beratungen für viele Lebenssituationen &emsp; Stand:&nbsp;{new Date().getFullYear()}</h1>

    <p style={{ fontSize: "1.2em", lineHeight: "1.7" }}>
          Kontakt:&ensp;
           <a href="tel:+49-9187-7081220" title="anrufen" style={{ fontSize: "1em"  }}>+49 (0) 91 87 / 70 81 220</a>
           &ensp;oder&ensp;
           <a href="mailto:12engel@bastet-project.de?subject=Anfrage zu einer Beratung" title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>12engel@bastet-project.de</a>
   </p>

   <p>
      Wer kennt folgende Situation?<br />
       Der Hund, das Pferd, die Familienmitglieder und/oder Personen in Ihrer Umgebung
       verhalten sich unverständlich oder sogar respektlos.
       Immer wieder fühlen Sie sich verzweifelt oder verärgert.
    </p>

    <p>
      Oder: "Eigentlich ist alles in Ordnung" und Sie fühlen sich dennoch ausgelaugt, niedergeschlagen oder ängstlich.
    </p>

    <p>
      Ich setze mich gerne mit Ihnen zusammen und wir erforschen gemeinsam die Besonderheiten Ihres Lebens.
      Wir beleuchten Hintergründe und Hürden und suchen Mittel und Möglichkeiten Ihren Weg zu ebnen und sogar
      zu gestalten. Obwohl keine zwei Lebewesen einander gleichen, so gibt es doch immer auch Gemeinsamkeiten.
      Schöpfen Sie also aus dem Wissenspool, dessen Inhalt ich in Jahrzehnten angehäuft habe,
      und erleuchten Sie Ihren Pfad und vielleicht sogar den Ihrer Liebsten...
    </p>

    <p className="poem">
      "Wer bereit ist, sich dem Kampf zu stellen ... dessen Leben ergibt einen Sinn."<br />
      <div style={{fontSize: "0.75em"}}>Zitat SG1 Folge "Colson"</div>
    </p>

    <p>
      Selbstverständlich wird Ihr Anliegen streng vertraulich behandelt.<br />
      Ich biete Ihnen sogar die Möglichkeit für einen anonymen Termin an einem neutralen Ort an:
      d.h. Sie können sich einen Namen oder ein "Code-Wort" ausdenken und ich vertraue Ihnen,
      dass Sie zu dem vereinbarten Termin an dem vereinbarten Ort erscheinen.
      Für das erste Gespräch hat sich ein neutraler Ort, wie ein Café oder ein öffentlicher Platz, bewährt.
    </p>

    <p>
      Bitte beachten Sie, dass meine Praktiken in keiner Konkurrenz zu der professionellen
      Arbeit eines Psychiaters oder Psychologen stehen.
      Somit kann ich Sie im Falle der Notwendigkeit einer fachärztlichen
      Therapie lediglich unterstützend begleiten.
    </p>

    <h1>Preis und Zahlungsmodalitäten (Stand: 2019)</h1>
    Zahlung in Bar vor Ort oder per Vorauskasse (z.B. durch Überweisung)
    <table>
      <tr>
        <td>
          Preis pro Stunde<br />
          bei Barzahlung:
        </td>
        <td class="seccol">
          75,-- EUR
        </td>
      </tr>

      <tr>
        <td>
          Preis pro Stunde<br />
          bei Vorauskasse:
        </td>
        <td class="seccol">
          70,-- EUR
        </td>
      </tr>

      <tr>
        <td>
          sonstige Preise und<br />
          Preise der Terminkarten:
        </td>
        <td class="seccol">
          nach vorheriger Vereinbarung<br />
          bzw. nach dem Erstgespräch
        </td>
      </tr>
    </table>

  </Layout>
              )

              export default BeratungKlartextPage
